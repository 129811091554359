import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TeamCard from "../components/TeamCard";

function TeamLeaderboard({ teams, positions }) {

  let [cards, setCards] = useState([])
  const [searchParams, setSearchParams] = useSearchParams();

  console.log(cards)

  let teamData = teams;

 /* let teamIds = [];

  teamData = teamData.filter((e) => {
    if (teamIds.includes(e.id)) {
      return false;
    } else {
      teamIds.push(e.id);
      return true;
    }
  });*/

  const mapCards = (teamData, posData) => {
    let posIds = [];

    posData = posData.filter((e) => {
      if (posIds.includes(e.id)) {
        return false;
      } else {
        posIds.push(e.id);
        return true;
      }
    });

    posData = posData.sort((a, b) => b.points - a.points);

   return teamData.map((team) => {
      return <TeamCard team={team} positions={posData} key={team.id} />;
    })
  };

  useEffect(() => {
    setCards(mapCards(teamData,positions))
  },[positions])

  return (
    <div className="centerAlign column" style={{ marginTop: "4vh" }}>
      <h1 style={{ color: "white", fontSize: "3vw" }}>Žebříček týmů</h1>

      <div
        className="teamList"
        style={{
          position: "relative",
          backgroundColor: "green",
          width: "78vw",
          marginBottom: "10vh",
        }}
      >
        {(cards && searchParams.get("pos")) ? cards[searchParams.get("pos")] : <>{cards}</>}
      </div>
    </div>
  );
}

export default TeamLeaderboard;
