import { useEffect, useState } from "react";

function TeamCard({team, positions}) {

  let [pos, setPos] = useState(5)
  let [points, setPoints] = useState(team.points)

  const getPos = (positions,team) => {
    if (!positions) {
      return 5
    }
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].name === team.name) {
        setPoints(positions[i].points)
        return i > 4 ? 5 : i+1
      }
    }
  }
  
  useEffect(() => {
    setPos(getPos(positions,team))
  }, [positions])

    const animDelay = ((pos-1) * 0.1) + "s" 
    let bgColor = "rgba(255,255,255, 0.7)"

    if (pos === 1) {
      bgColor = "linear-gradient(45deg, rgba(223,189,105,1) 0%, rgba(146,111,52,1) 100%)";
    } else if (pos === 2) {
      //bgColor = "linear-gradient(45deg, rgba(233,233,233,1) 22.8%, rgba(238,238,238,1) 42.4%, rgba(233,233,233,1) 62.98%, rgba(94,94,94,1) 80%);"
      bgColor = "linear-gradient(45deg, rgba(217,217,217,1) 28%, rgba(119,119,119,1) 100%)"
    } else if (pos === 3) {
      bgColor = "linear-gradient(45deg, #db6c2b, #673208)"
    }

    let top = (pos-1)*21

    const style = {animationDelay: animDelay, background: bgColor, textDecoration: "none", transition: "top ease 1s", position: "absolute", top: top + "vh"}

  return (
    <div className={"teamCard row "/* + classs*/} style={style} id={team.id}>
        <h2 style={{margin: "0vw"}}>{"#" + pos}</h2>
      <img
        className="pfp"
        src={team.pfp}
        alt={"Ikona týmu " + team.name}
      />
      <div className="column">
        <h1 style={{margin: "0vw"}}>{team.name}</h1>
        <h2 style={{margin: "0vw"}}>{points + " bodů"}</h2>
      </div>
    </div>
  );
}

export default TeamCard;
