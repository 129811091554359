import { BrowserRouter, Route, Routes } from "react-router-dom";
import TeamLeaderboard from "./pages/TeamLeaderboard";
import { useState } from "react";
import { getAnsweredProblems, getProblems, getTeams } from "./scripts/dataHandler";
import ProblemLeaderboard from "./pages/ProblemLeaderboard";
import TeamLeaderboardScroll from "./pages/TeamLeaderboardScroll";

function App() {

  let [teamsData,setTeamData] = useState([])
  let [teamPositions, setTeamPositions] = useState([])

  let [problems, setProblems] = useState([])
  let [answers, setAnswers] = useState([])

  const getData = () => {
    console.log("getting data")
    getTeams(setTeamPositions)
    getProblems(setProblems,false)
    getAnsweredProblems(setAnswers)
  }

  useState(() => {
    
    getTeams(setTeamData)

    getData()
    setInterval(getData, 30000)
  },[])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<>hola mi amigo</>} />
        <Route path="/teamLeaderboard" element={<TeamLeaderboard teams={teamsData} positions={teamPositions}/>} />
        <Route path="/teamLeaderboardScroll" element={<TeamLeaderboardScroll teams={teamsData} positions={teamPositions}/>} />
        {/*<Route path="/problemLeaderboard" element={<>{teamsData && <ProblemLeaderboard problems={problems} answers={answers} teamN={teamsData.length}/>}</>}/>*/}
        <Route path="/problemLeaderboard" element={<>{<ProblemLeaderboard problems={problems} answers={answers} teamN={285}/>}</>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
