import { useEffect, useState } from "react";
import TeamCard2 from "../components/TeamCard2";

function TeamLeaderboardScroll({ teams, positions }) {

  let [cards, setCards] = useState([])

  let teamData = teams;

  const mapCards = (teamData, posData) => {
   return teamData.map((team) => {
      return <TeamCard2 team={team} positions={posData} key={team.id} />;
    })
  };

  const scroll = (y) => {
    y += 1
    window.scrollBy(0,1)
    setTimeout(() => {scroll(y)}, 40)
  }

  setTimeout(() => {scroll(0)},5000)

  useEffect(() => {
    setCards(mapCards(teamData,positions))
  },[positions])

  return (
    <div className="centerAlign column" style={{ marginTop: "4vh" }}>
      <h1 style={{ color: "white", fontSize: "3vw" }}>Žebříček týmů</h1>

      <div
        className="teamList"
        style={{
          position: "relative",
          backgroundColor: "green",
          width: "78vw",
          marginBottom: "10vh",
        }}
      >
        {cards}
      </div>
    </div>
  );
}

export default TeamLeaderboardScroll;
