function TreeNode({problem,answered,teamN}) {
    
    const style = {"position": "absolute", "top": 0, "left:": 0,"transform": "translate(" + (problem.xPos-0.2) +  "vw," + (problem.yPos - 2.5) + "vh)"};
    /*let bgColor = ""
    if (state == "active") {
        switch (problem.type) {
            case "s":
                bgColor = "#ea3a60"
                break
            case "m":
                bgColor = "#75fb4c"
                break
            case "l":
                bgColor = "#0000f5"
                break
        }
    }*/

    const text = answered ? answered : 0;

    let activeClass = "disabled"
    if (text) {
        if (text === teamN) {
            activeClass = "solved"
        } else {
            activeClass = "active"
        }
    }

    const classes = "treeNode centerAlign " + activeClass

    /*const type = problem.type == "s" ? "Š" : problem.type.toUpperCase()*/
    return ( 
        <div style={style}>
        <div className={classes} /*style={{backgroundColor: bgColor}}*/>
            <h2>{text}</h2>
        </div>
        </div>
     );
}

export default TreeNode;