import TreeNode from "../components/TreeNode";
import Line from "../components/Line";

function ProblemLeaderboard({ problems, answers, teamN }) {

  const mapProblems = (problemData,answers) => {
    return problemData.map((problem) => {
      return (
        <TreeNode
          problem={problem}
          answered={answers ? answers[problem.id] : 0}
          teamN={teamN}
          key={"problem" + problem.id}
        />
      );
    });
  };

  const mapLines = (problemData) => {
    let lines = [];
    problemData.map((problem) => {
      if (!problem.childrenIds) {
        return <></>;
      }
      let i = 0;
      return problem.childrenIds.map((childId) => {
        i += 1;
        const child = problemData[childId];
        lines.push(
          <Line
            x1={problem.xPos + 50 + "vw"}
            y1={problem.yPos + "vh"}
            x2={child.xPos + 50 + "vw"}
            y2={child.yPos + "vh"}
            key={problem.id + ":" + i}
          />
        );
        return <></>;
      });
    });
    return lines;
  };

  return (
    <>
      <div style={{ marginLeft: "10vw", marginTop: "2vh" }}>
        <div className="centerAlign treeContainer" style={{ width: "80vw" }}>
          <div style={{ position: "relative" }}>
            <svg
              width="100vw"
              height="100vh"
              style={{ transform: "translate(0vw,2vh)", overflow: "hidden" }}
            >
              {problems && mapLines(problems)}
            </svg>
          </div>

          {problems && answers && mapProblems(problems,answers)}
        </div>
      </div>
    </>
  );
}

export default ProblemLeaderboard;