//const url = "http://localhost:8080/"
const url = "https://omega-post-416123.ey.r.appspot.com/"

export const getTeams = (setTeams) => {
    fetch(url + "getData/teamsFull")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTeams(data.teams)
      });
  };

/*const processTeams = (teamData) => {
  let teamIds = [];

  teamData = teamData.filter((e) => {
    if (teamIds.includes(e.id)) {
      return false;
    } else {
      teamIds.push(e.id);
      return true;
    }
  });

  return teamData
}*/

  export const getProblems = (setProblems, retry) => {
    fetch(url + "getData/problems", {
      method: "POST",
      body: JSON.stringify({
        token: "TmDZBDLd7yaYtYHEkvKv",
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (!data.problems) {
          setProblems([]);
        } else {
          setProblems(processProblems(data.problems));
        }
        return
      });
  };

const processProblems = (problems) => {
  problems.forEach(e => {
      e.yPos = e.yPos -5
      if (e.yPos < 0) {
          e.yPos = 5
      }
      if (e.yPos > 100) {
          e.yPos = 85
      }
  });

  return problems
}
  
export const getAnsweredProblems = (setAnswers) => {
  fetch(url + "acceptData/getAnswerCounter", {
    method: "POST",
    body: JSON.stringify({
      pass: "chl.oPL!O45sVII_kr",
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      if (json.data) {
        setAnswers(json.data)
      }
      return
    });
}
